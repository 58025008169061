
var ortWasm = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(moduleArg = {}) {

var f=moduleArg,k,l;f.ready=new Promise((a,b)=>{k=a;l=b});var aa=Object.assign({},f),ba="./this.program",ca="object"==typeof window,q="function"==typeof importScripts,da="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,v="",x,z,A;
if(da){var fs=require("fs"),B=require("path");v=q?B.dirname(v)+"/":__dirname+"/";x=(a,b)=>{a=C(a)?new URL(a):B.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")};A=a=>{a=x(a,!0);a.buffer||(a=new Uint8Array(a));return a};z=(a,b,c,e=!0)=>{a=C(a)?new URL(a):B.normalize(a);fs.readFile(a,e?void 0:"utf8",(g,h)=>{g?c(g):b(e?h.buffer:h)})};!f.thisProgram&&1<process.argv.length&&(ba=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);f.inspect=()=>"[Emscripten Module object]"}else if(ca||q)q?v=
self.location.href:"undefined"!=typeof document&&document.currentScript&&(v=document.currentScript.src),_scriptDir&&(v=_scriptDir),0!==v.indexOf("blob:")?v=v.substr(0,v.replace(/[?#].*/,"").lastIndexOf("/")+1):v="",x=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},q&&(A=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),z=(a,b,c)=>{var e=new XMLHttpRequest;e.open("GET",a,!0);e.responseType=
"arraybuffer";e.onload=()=>{200==e.status||0==e.status&&e.response?b(e.response):c()};e.onerror=c;e.send(null)};var ea=console.log.bind(console),D=console.error.bind(console);Object.assign(f,aa);aa=null;"object"!=typeof WebAssembly&&E("no native wasm support detected");var F,fa=!1,G,H,I,J,ha;
function ia(){var a=F.buffer;f.HEAP8=G=new Int8Array(a);f.HEAP16=new Int16Array(a);f.HEAPU8=H=new Uint8Array(a);f.HEAPU16=new Uint16Array(a);f.HEAP32=I=new Int32Array(a);f.HEAPU32=J=new Uint32Array(a);f.HEAPF32=new Float32Array(a);f.HEAPF64=ha=new Float64Array(a)}var K=[],L=[],ja=[],M=0,N=null,O=null;function E(a){a="Aborted("+a+")";D(a);fa=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");l(a);throw a;}
var ka=a=>a.startsWith("data:application/octet-stream;base64,"),C=a=>a.startsWith("file://"),P;P="ort-wasm.wasm";if(!ka(P)){var la=P;P=f.locateFile?f.locateFile(la,v):v+la}function ma(a){if(A)return A(a);throw"both async and sync fetching of the wasm failed";}
function na(a){if(ca||q){if("function"==typeof fetch&&!C(a))return fetch(a,{credentials:"same-origin"}).then(b=>{if(!b.ok)throw"failed to load wasm binary file at '"+a+"'";return b.arrayBuffer()}).catch(()=>ma(a));if(z)return new Promise((b,c)=>{z(a,e=>b(new Uint8Array(e)),c)})}return Promise.resolve().then(()=>ma(a))}function oa(a,b,c){return na(a).then(e=>WebAssembly.instantiate(e,b)).then(e=>e).then(c,e=>{D(`failed to asynchronously prepare wasm: ${e}`);E(e)})}
function pa(a,b){var c=P;return"function"!=typeof WebAssembly.instantiateStreaming||ka(c)||C(c)||da||"function"!=typeof fetch?oa(c,a,b):fetch(c,{credentials:"same-origin"}).then(e=>WebAssembly.instantiateStreaming(e,a).then(b,function(g){D(`wasm streaming compile failed: ${g}`);D("falling back to ArrayBuffer instantiation");return oa(c,a,b)}))}
var Q,qa={919888:(a,b,c,e)=>{if("undefined"==typeof f||!f.za)return 1;a=R(a>>>0);a.startsWith("./")&&(a=a.substring(2));a=f.za.get(a);if(!a)return 2;b>>>=0;c>>>=0;if(b+c>a.byteLength)return 3;try{return H.set(a.subarray(b,b+c),e>>>0>>>0),0}catch{return 4}}};function ra(a){this.xa=a-24;this.Ha=function(b){J[this.xa+4>>>2>>>0]=b};this.Ga=function(b){J[this.xa+8>>>2>>>0]=b};this.Aa=function(b,c){this.Fa();this.Ha(b);this.Ga(c)};this.Fa=function(){J[this.xa+16>>>2>>>0]=0}}
var sa=0,ta=0,ua="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,va=(a,b,c)=>{b>>>=0;var e=b+c;for(c=b;a[c]&&!(c>=e);)++c;if(16<c-b&&a.buffer&&ua)return ua.decode(a.subarray(b,c));for(e="";b<c;){var g=a[b++];if(g&128){var h=a[b++]&63;if(192==(g&224))e+=String.fromCharCode((g&31)<<6|h);else{var m=a[b++]&63;g=224==(g&240)?(g&15)<<12|h<<6|m:(g&7)<<18|h<<12|m<<6|a[b++]&63;65536>g?e+=String.fromCharCode(g):(g-=65536,e+=String.fromCharCode(55296|g>>10,56320|g&1023))}}else e+=String.fromCharCode(g)}return e},
R=(a,b)=>(a>>>=0)?va(H,a,b):"",S=a=>{for(var b=0,c=0;c<a.length;++c){var e=a.charCodeAt(c);127>=e?b++:2047>=e?b+=2:55296<=e&&57343>=e?(b+=4,++c):b+=3}return b},T=(a,b,c,e)=>{c>>>=0;if(!(0<e))return 0;var g=c;e=c+e-1;for(var h=0;h<a.length;++h){var m=a.charCodeAt(h);if(55296<=m&&57343>=m){var r=a.charCodeAt(++h);m=65536+((m&1023)<<10)|r&1023}if(127>=m){if(c>=e)break;b[c++>>>0]=m}else{if(2047>=m){if(c+1>=e)break;b[c++>>>0]=192|m>>6}else{if(65535>=m){if(c+2>=e)break;b[c++>>>0]=224|m>>12}else{if(c+3>=
e)break;b[c++>>>0]=240|m>>18;b[c++>>>0]=128|m>>12&63}b[c++>>>0]=128|m>>6&63}b[c++>>>0]=128|m&63}}b[c>>>0]=0;return c-g},U=a=>0===a%4&&(0!==a%100||0===a%400),wa=[0,31,60,91,121,152,182,213,244,274,305,335],xa=[0,31,59,90,120,151,181,212,243,273,304,334],Ca=a=>{var b=S(a)+1,c=Ba(b);c&&T(a,H,c,b);return c},V=[],W={},Da=()=>{if(!X){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-",
"_")+".UTF-8",_:ba||"./this.program"},b;for(b in W)void 0===W[b]?delete a[b]:a[b]=W[b];var c=[];for(b in a)c.push(`${b}=${a[b]}`);X=c}return X},X,Ea=[null,[],[]],Fa=[31,29,31,30,31,30,31,31,30,31,30,31],Ga=[31,28,31,30,31,30,31,31,30,31,30,31];function Ha(a){var b=Array(S(a)+1);T(a,b,0,b.length);return b}
function Ia(a,b,c,e){function g(d,n,p){for(d="number"==typeof d?d.toString():d||"";d.length<n;)d=p[0]+d;return d}function h(d,n){return g(d,n,"0")}function m(d,n){function p(ya){return 0>ya?-1:0<ya?1:0}var y;0===(y=p(d.getFullYear()-n.getFullYear()))&&0===(y=p(d.getMonth()-n.getMonth()))&&(y=p(d.getDate()-n.getDate()));return y}function r(d){switch(d.getDay()){case 0:return new Date(d.getFullYear()-1,11,29);case 1:return d;case 2:return new Date(d.getFullYear(),0,3);case 3:return new Date(d.getFullYear(),
0,2);case 4:return new Date(d.getFullYear(),0,1);case 5:return new Date(d.getFullYear()-1,11,31);case 6:return new Date(d.getFullYear()-1,11,30)}}function w(d){var n=d.ta;for(d=new Date((new Date(d.ua+1900,0,1)).getTime());0<n;){var p=d.getMonth(),y=(U(d.getFullYear())?Fa:Ga)[p];if(n>y-d.getDate())n-=y-d.getDate()+1,d.setDate(1),11>p?d.setMonth(p+1):(d.setMonth(0),d.setFullYear(d.getFullYear()+1));else{d.setDate(d.getDate()+n);break}}p=new Date(d.getFullYear()+1,0,4);n=r(new Date(d.getFullYear(),
0,4));p=r(p);return 0>=m(n,d)?0>=m(p,d)?d.getFullYear()+1:d.getFullYear():d.getFullYear()-1}a>>>=0;b>>>=0;c>>>=0;e>>>=0;var t=J[e+40>>>2>>>0];e={Da:I[e>>>2>>>0],Ca:I[e+4>>>2>>>0],va:I[e+8>>>2>>>0],ya:I[e+12>>>2>>>0],wa:I[e+16>>>2>>>0],ua:I[e+20>>>2>>>0],oa:I[e+24>>>2>>>0],ta:I[e+28>>>2>>>0],Ia:I[e+32>>>2>>>0],Ba:I[e+36>>>2>>>0],Ea:t?R(t):""};c=R(c);t={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S",
"%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var u in t)c=c.replace(new RegExp(u,"g"),t[u]);var za="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),Aa="January February March April May June July August September October November December".split(" ");t={"%a":d=>za[d.oa].substring(0,3),"%A":d=>za[d.oa],
"%b":d=>Aa[d.wa].substring(0,3),"%B":d=>Aa[d.wa],"%C":d=>h((d.ua+1900)/100|0,2),"%d":d=>h(d.ya,2),"%e":d=>g(d.ya,2," "),"%g":d=>w(d).toString().substring(2),"%G":d=>w(d),"%H":d=>h(d.va,2),"%I":d=>{d=d.va;0==d?d=12:12<d&&(d-=12);return h(d,2)},"%j":d=>{for(var n=0,p=0;p<=d.wa-1;n+=(U(d.ua+1900)?Fa:Ga)[p++]);return h(d.ya+n,3)},"%m":d=>h(d.wa+1,2),"%M":d=>h(d.Ca,2),"%n":()=>"\n","%p":d=>0<=d.va&&12>d.va?"AM":"PM","%S":d=>h(d.Da,2),"%t":()=>"\t","%u":d=>d.oa||7,"%U":d=>h(Math.floor((d.ta+7-d.oa)/7),
2),"%V":d=>{var n=Math.floor((d.ta+7-(d.oa+6)%7)/7);2>=(d.oa+371-d.ta-2)%7&&n++;if(n)53==n&&(p=(d.oa+371-d.ta)%7,4==p||3==p&&U(d.ua)||(n=1));else{n=52;var p=(d.oa+7-d.ta-1)%7;(4==p||5==p&&U(d.ua%400-1))&&n++}return h(n,2)},"%w":d=>d.oa,"%W":d=>h(Math.floor((d.ta+7-(d.oa+6)%7)/7),2),"%y":d=>(d.ua+1900).toString().substring(2),"%Y":d=>d.ua+1900,"%z":d=>{d=d.Ba;var n=0<=d;d=Math.abs(d)/60;return(n?"+":"-")+String("0000"+(d/60*100+d%60)).slice(-4)},"%Z":d=>d.Ea,"%%":()=>"%"};c=c.replace(/%%/g,"\x00\x00");
for(u in t)c.includes(u)&&(c=c.replace(new RegExp(u,"g"),t[u](e)));c=c.replace(/\0\0/g,"%");u=Ha(c);if(u.length>b)return 0;G.set(u,a>>>0);return u.length-1}
var La={a:function(a,b,c){a>>>=0;(new ra(a)).Aa(b>>>0,c>>>0);sa=a;ta++;throw sa;},e:function(){return 0},H:function(){},x:function(){},z:function(){},J:function(){return 0},F:function(){},A:function(){},E:function(){},g:function(){},y:function(){},v:function(){},G:function(){},w:function(){},k:()=>1,n:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);I[c>>>2>>>0]=a.getUTCSeconds();I[c+4>>>2>>>0]=a.getUTCMinutes();I[c+8>>>2>>>0]=a.getUTCHours();I[c+12>>>
2>>>0]=a.getUTCDate();I[c+16>>>2>>>0]=a.getUTCMonth();I[c+20>>>2>>>0]=a.getUTCFullYear()-1900;I[c+24>>>2>>>0]=a.getUTCDay();I[c+28>>>2>>>0]=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0},o:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);I[c>>>2>>>0]=a.getSeconds();I[c+4>>>2>>>0]=a.getMinutes();I[c+8>>>2>>>0]=a.getHours();I[c+12>>>2>>>0]=a.getDate();I[c+16>>>2>>>0]=a.getMonth();I[c+20>>>2>>>0]=a.getFullYear()-1900;I[c+24>>>2>>>0]=a.getDay();
I[c+28>>>2>>>0]=(U(a.getFullYear())?wa:xa)[a.getMonth()]+a.getDate()-1|0;I[c+36>>>2>>>0]=-(60*a.getTimezoneOffset());b=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();var e=(new Date(a.getFullYear(),0,1)).getTimezoneOffset();I[c+32>>>2>>>0]=(b!=e&&a.getTimezoneOffset()==Math.min(e,b))|0},p:function(a){a>>>=0;var b=new Date(I[a+20>>>2>>>0]+1900,I[a+16>>>2>>>0],I[a+12>>>2>>>0],I[a+8>>>2>>>0],I[a+4>>>2>>>0],I[a>>>2>>>0],0),c=I[a+32>>>2>>>0],e=b.getTimezoneOffset(),g=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),
h=(new Date(b.getFullYear(),0,1)).getTimezoneOffset(),m=Math.min(h,g);0>c?I[a+32>>>2>>>0]=Number(g!=h&&m==e):0<c!=(m==e)&&(g=Math.max(h,g),b.setTime(b.getTime()+6E4*((0<c?m:g)-e)));I[a+24>>>2>>>0]=b.getDay();I[a+28>>>2>>>0]=(U(b.getFullYear())?wa:xa)[b.getMonth()]+b.getDate()-1|0;I[a>>>2>>>0]=b.getSeconds();I[a+4>>>2>>>0]=b.getMinutes();I[a+8>>>2>>>0]=b.getHours();I[a+12>>>2>>>0]=b.getDate();I[a+16>>>2>>>0]=b.getMonth();I[a+20>>>2>>>0]=b.getYear();a=b.getTime();isNaN(a)?(I[Ja()>>>2>>>0]=61,a=-1):
a/=1E3;return Ka((Q=a,1<=+Math.abs(Q)?0<Q?+Math.floor(Q/4294967296)>>>0:~~+Math.ceil((Q-+(~~Q>>>0))/4294967296)>>>0:0)),a>>>0},l:function(){return-52},m:function(){},t:function(a,b,c){function e(w){return(w=w.toTimeString().match(/\(([A-Za-z ]+)\)$/))?w[1]:"GMT"}c>>>=0;var g=(new Date).getFullYear(),h=new Date(g,0,1),m=new Date(g,6,1);g=h.getTimezoneOffset();var r=m.getTimezoneOffset();J[a>>>0>>>2>>>0]=60*Math.max(g,r);I[b>>>0>>>2>>>0]=Number(g!=r);a=e(h);b=e(m);a=Ca(a);b=Ca(b);r<g?(J[c>>>2>>>0]=
a,J[c+4>>>2>>>0]=b):(J[c>>>2>>>0]=b,J[c+4>>>2>>>0]=a)},d:()=>{E("")},B:function(a,b,c){a>>>=0;b>>>=0;c>>>=0;V.length=0;for(var e;e=H[b++>>>0];){var g=105!=e;g&=112!=e;c+=g&&c%8?4:0;V.push(112==e?J[c>>>2>>>0]:105==e?I[c>>>2>>>0]:ha[c>>>3>>>0]);c+=g?8:4}return qa[a].apply(null,V)},h:()=>Date.now(),u:function(){return 4294901760},b:()=>performance.now(),I:function(a,b,c){b>>>=0;return H.copyWithin(a>>>0>>>0,b>>>0,b+(c>>>0)>>>0)},s:function(a){a>>>=0;var b=H.length;if(4294901760<a)return!1;for(var c=
1;4>=c;c*=2){var e=b*(1+.2/c);e=Math.min(e,a+100663296);var g=Math;e=Math.max(a,e);a:{g=(g.min.call(g,4294901760,e+(65536-e%65536)%65536)-F.buffer.byteLength+65535)/65536;try{F.grow(g);ia();var h=1;break a}catch(m){}h=void 0}if(h)return!0}return!1},C:function(a,b){a>>>=0;b>>>=0;var c=0;Da().forEach((e,g)=>{var h=b+c;g=J[a+4*g>>>2>>>0]=h;for(h=0;h<e.length;++h)G[g++>>>0>>>0]=e.charCodeAt(h);G[g>>>0>>>0]=0;c+=e.length+1});return 0},D:function(a,b){a>>>=0;b>>>=0;var c=Da();J[a>>>2>>>0]=c.length;var e=
0;c.forEach(g=>e+=g.length+1);J[b>>>2>>>0]=e;return 0},f:()=>52,j:function(){return 52},q:function(){return 70},i:function(a,b,c,e){b>>>=0;c>>>=0;e>>>=0;for(var g=0,h=0;h<c;h++){var m=J[b>>>2>>>0],r=J[b+4>>>2>>>0];b+=8;for(var w=0;w<r;w++){var t=H[m+w>>>0],u=Ea[a];0===t||10===t?((1===a?ea:D)(va(u,0)),u.length=0):u.push(t)}g+=r}J[e>>>2>>>0]=g;return 0},r:Ia,c:function(a,b,c,e){return Ia(a>>>0,b>>>0,c>>>0,e>>>0)}},Y=function(){function a(c){Y=c.exports;Y=Ma();F=Y.K;ia();L.unshift(Y.L);M--;0==M&&(null!==
N&&(clearInterval(N),N=null),O&&(c=O,O=null,c()));return Y}var b={a:La};M++;if(f.instantiateWasm)try{return f.instantiateWasm(b,a)}catch(c){D(`Module.instantiateWasm callback failed with error: ${c}`),l(c)}pa(b,function(c){a(c.instance)}).catch(l);return{}}();f._OrtInit=(a,b)=>(f._OrtInit=Y.M)(a,b);f._OrtGetLastError=(a,b)=>(f._OrtGetLastError=Y.N)(a,b);f._OrtCreateSessionOptions=(a,b,c,e,g,h,m,r,w,t)=>(f._OrtCreateSessionOptions=Y.O)(a,b,c,e,g,h,m,r,w,t);
f._OrtAppendExecutionProvider=(a,b)=>(f._OrtAppendExecutionProvider=Y.P)(a,b);f._OrtAddFreeDimensionOverride=(a,b,c)=>(f._OrtAddFreeDimensionOverride=Y.Q)(a,b,c);f._OrtAddSessionConfigEntry=(a,b,c)=>(f._OrtAddSessionConfigEntry=Y.R)(a,b,c);f._OrtReleaseSessionOptions=a=>(f._OrtReleaseSessionOptions=Y.S)(a);f._OrtCreateSession=(a,b,c)=>(f._OrtCreateSession=Y.T)(a,b,c);f._OrtReleaseSession=a=>(f._OrtReleaseSession=Y.U)(a);f._OrtGetInputOutputCount=(a,b,c)=>(f._OrtGetInputOutputCount=Y.V)(a,b,c);
f._OrtGetInputName=(a,b)=>(f._OrtGetInputName=Y.W)(a,b);f._OrtGetOutputName=(a,b)=>(f._OrtGetOutputName=Y.X)(a,b);f._OrtFree=a=>(f._OrtFree=Y.Y)(a);f._OrtCreateTensor=(a,b,c,e,g,h)=>(f._OrtCreateTensor=Y.Z)(a,b,c,e,g,h);f._OrtGetTensorData=(a,b,c,e,g)=>(f._OrtGetTensorData=Y._)(a,b,c,e,g);f._OrtReleaseTensor=a=>(f._OrtReleaseTensor=Y.$)(a);f._OrtCreateRunOptions=(a,b,c,e)=>(f._OrtCreateRunOptions=Y.aa)(a,b,c,e);f._OrtAddRunConfigEntry=(a,b,c)=>(f._OrtAddRunConfigEntry=Y.ba)(a,b,c);
f._OrtReleaseRunOptions=a=>(f._OrtReleaseRunOptions=Y.ca)(a);f._OrtCreateBinding=a=>(f._OrtCreateBinding=Y.da)(a);f._OrtBindInput=(a,b,c)=>(f._OrtBindInput=Y.ea)(a,b,c);f._OrtBindOutput=(a,b,c,e)=>(f._OrtBindOutput=Y.fa)(a,b,c,e);f._OrtClearBoundOutputs=a=>(f._OrtClearBoundOutputs=Y.ga)(a);f._OrtReleaseBinding=a=>(f._OrtReleaseBinding=Y.ha)(a);f._OrtRunWithBinding=(a,b,c,e,g)=>(f._OrtRunWithBinding=Y.ia)(a,b,c,e,g);f._OrtRun=(a,b,c,e,g,h,m,r)=>(f._OrtRun=Y.ja)(a,b,c,e,g,h,m,r);
f._OrtEndProfiling=a=>(f._OrtEndProfiling=Y.ka)(a);var Ja=()=>(Ja=Y.la)(),Ba=f._malloc=a=>(Ba=f._malloc=Y.ma)(a);f._free=a=>(f._free=Y.na)(a);var Ka=a=>(Ka=Y.pa)(a),Na=()=>(Na=Y.qa)(),Oa=a=>(Oa=Y.ra)(a),Pa=a=>(Pa=Y.sa)(a);function Ma(){var a=Y;a=Object.assign({},a);var b=e=>()=>e()>>>0,c=e=>g=>e(g)>>>0;a.la=b(a.la);a.ma=c(a.ma);a.qa=b(a.qa);a.sa=c(a.sa);return a}f.stackAlloc=Pa;f.stackSave=Na;f.stackRestore=Oa;f.UTF8ToString=R;f.stringToUTF8=(a,b,c)=>T(a,H,b,c);f.lengthBytesUTF8=S;var Z;
O=function Qa(){Z||Ra();Z||(O=Qa)};function Ra(){if(!(0<M)){if(f.preRun)for("function"==typeof f.preRun&&(f.preRun=[f.preRun]);f.preRun.length;){var a=f.preRun.shift();K.unshift(a)}for(;0<K.length;)K.shift()(f);if(!(0<M||Z||(Z=!0,f.calledRun=!0,fa))){for(;0<L.length;)L.shift()(f);for(k(f);0<ja.length;)ja.shift()(f)}}}Ra();


  return moduleArg.ready
}
);
})();
;
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasm;
else if (typeof define === 'function' && define['amd'])
  define([], () => ortWasm);
