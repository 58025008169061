
var ortWasmThreaded = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(moduleArg = {}) {

function aa(){e.buffer!=l.buffer&&m();return l}function n(){e.buffer!=l.buffer&&m();return ba}function p(){e.buffer!=l.buffer&&m();return ca}function r(){e.buffer!=l.buffer&&m();return da}function ea(){e.buffer!=l.buffer&&m();return fa}var v=moduleArg,ha,x;v.ready=new Promise((a,b)=>{ha=a;x=b});
var ia=Object.assign({},v),ja="./this.program",z=(a,b)=>{throw b;},ka="object"==typeof window,A="function"==typeof importScripts,B="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,D=v.ENVIRONMENT_IS_PTHREAD||!1,E="";function la(a){return v.locateFile?v.locateFile(a,E):E+a}var ma,G,H;
if(B){var fs=require("fs"),na=require("path");E=A?na.dirname(E)+"/":__dirname+"/";ma=(b,c)=>{b=I(b)?new URL(b):na.normalize(b);return fs.readFileSync(b,c?void 0:"utf8")};H=b=>{b=ma(b,!0);b.buffer||(b=new Uint8Array(b));return b};G=(b,c,d,g=!0)=>{b=I(b)?new URL(b):na.normalize(b);fs.readFile(b,g?void 0:"utf8",(h,k)=>{h?d(h):c(g?k.buffer:k)})};!v.thisProgram&&1<process.argv.length&&(ja=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);z=(b,c)=>{process.exitCode=b;throw c;};v.inspect=()=>"[Emscripten Module object]";
let a;try{a=require("worker_threads")}catch(b){throw console.error('The "worker_threads" module is not supported in this node.js build - perhaps a newer version is needed?'),b;}global.Worker=a.Worker}else if(ka||A)A?E=self.location.href:"undefined"!=typeof document&&document.currentScript&&(E=document.currentScript.src),(typeof _scriptDir !== "undefined" && _scriptDir)&&(E=_scriptDir),0!==E.indexOf("blob:")?E=E.substr(0,E.replace(/[?#].*/,"").lastIndexOf("/")+1):E="",B||(ma=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);
return b.responseText},A&&(H=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),G=(a,b,c)=>{var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType="arraybuffer";d.onload=()=>{200==d.status||0==d.status&&d.response?b(d.response):c()};d.onerror=c;d.send(null)});B&&"undefined"==typeof performance&&(global.performance=require("perf_hooks").performance);var oa=console.log.bind(console),pa=console.error.bind(console);
B&&(oa=(...a)=>fs.writeSync(1,a.join(" ")+"\n"),pa=(...a)=>fs.writeSync(2,a.join(" ")+"\n"));var qa=oa,J=pa;Object.assign(v,ia);ia=null;"object"!=typeof WebAssembly&&ra("no native wasm support detected");var e,sa,K=!1,L,l,ba,ca,da,fa;function m(){var a=e.buffer;v.HEAP8=l=new Int8Array(a);v.HEAP16=new Int16Array(a);v.HEAPU8=ba=new Uint8Array(a);v.HEAPU16=new Uint16Array(a);v.HEAP32=ca=new Int32Array(a);v.HEAPU32=da=new Uint32Array(a);v.HEAPF32=new Float32Array(a);v.HEAPF64=fa=new Float64Array(a)}
var ta=16777216;
if(D)e=v.wasmMemory;else if(v.wasmMemory)e=v.wasmMemory;else if(e=new WebAssembly.Memory({initial:ta/65536,maximum:65536,shared:!0}),!(e.buffer instanceof SharedArrayBuffer))throw J("requested a shared WebAssembly.Memory but the returned buffer is not a SharedArrayBuffer, indicating that while the browser has SharedArrayBuffer it does not have WebAssembly threads support - you may need to set a flag"),B&&J("(on node you may need: --experimental-wasm-threads --experimental-wasm-bulk-memory and/or recent version)"),Error("bad memory");
m();ta=e.buffer.byteLength;var ua=[],va=[],wa=[],M=0,xa=null,N=null;function ya(){M--;if(0==M&&(null!==xa&&(clearInterval(xa),xa=null),N)){var a=N;N=null;a()}}function ra(a){a="Aborted("+a+")";J(a);K=!0;L=1;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");x(a);throw a;}var za=a=>a.startsWith("data:application/octet-stream;base64,"),I=a=>a.startsWith("file://"),O;O="ort-wasm-threaded.wasm";za(O)||(O=la(O));
function Aa(a){if(H)return H(a);throw"both async and sync fetching of the wasm failed";}function Ba(a){if(ka||A){if("function"==typeof fetch&&!I(a))return fetch(a,{credentials:"same-origin"}).then(b=>{if(!b.ok)throw"failed to load wasm binary file at '"+a+"'";return b.arrayBuffer()}).catch(()=>Aa(a));if(G)return new Promise((b,c)=>{G(a,d=>b(new Uint8Array(d)),c)})}return Promise.resolve().then(()=>Aa(a))}
function Ca(a,b,c){return Ba(a).then(d=>WebAssembly.instantiate(d,b)).then(d=>d).then(c,d=>{J(`failed to asynchronously prepare wasm: ${d}`);ra(d)})}function Da(a,b){var c=O;return"function"!=typeof WebAssembly.instantiateStreaming||za(c)||I(c)||B||"function"!=typeof fetch?Ca(c,a,b):fetch(c,{credentials:"same-origin"}).then(d=>WebAssembly.instantiateStreaming(d,a).then(b,function(g){J(`wasm streaming compile failed: ${g}`);J("falling back to ArrayBuffer instantiation");return Ca(c,a,b)}))}
var P,Ea={921276:(a,b,c,d)=>{if("undefined"==typeof v||!v.cb)return 1;a=Q(a>>>0);a.startsWith("./")&&(a=a.substring(2));a=v.cb.get(a);if(!a)return 2;b>>>=0;c>>>=0;d>>>=0;if(b+c>a.byteLength)return 3;try{return n().set(a.subarray(b,b+c),d>>>0),0}catch{return 4}}};function R(a){this.name="ExitStatus";this.message=`Program terminated with exit(${a})`;this.status=a}
var Fa=a=>{a.terminate();a.onmessage=()=>{}},Ha=a=>{0==S.Pa.length&&(Ga(),S.Ya(S.Pa[0]));var b=S.Pa.pop();if(!b)return 6;S.Qa.push(b);S.Ma[a.Oa]=b;b.Oa=a.Oa;var c={cmd:"run",start_routine:a.kb,arg:a.hb,pthread_ptr:a.Oa};B&&b.unref();b.postMessage(c,a.qb);return 0},T=0,Ia="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,Ja=(a,b,c)=>{b>>>=0;var d=b+c;for(c=b;a[c]&&!(c>=d);)++c;if(16<c-b&&a.buffer&&Ia)return Ia.decode(a.buffer instanceof SharedArrayBuffer?a.slice(b,c):a.subarray(b,c));
for(d="";b<c;){var g=a[b++];if(g&128){var h=a[b++]&63;if(192==(g&224))d+=String.fromCharCode((g&31)<<6|h);else{var k=a[b++]&63;g=224==(g&240)?(g&15)<<12|h<<6|k:(g&7)<<18|h<<12|k<<6|a[b++]&63;65536>g?d+=String.fromCharCode(g):(g-=65536,d+=String.fromCharCode(55296|g>>10,56320|g&1023))}}else d+=String.fromCharCode(g)}return d},Q=(a,b)=>(a>>>=0)?Ja(n(),a,b):"",La=a=>{var b=Ka();a=a();U(b);return a};
function V(a,b){var c=arguments.length-2,d=arguments;return La(()=>{for(var g=Ma(8*c),h=g>>>3,k=0;k<c;k++){var t=d[2+k];ea()[h+k>>>0]=t}return Na(a,c,g,b)})}function Oa(a){if(D)return V(0,1,a);L=a;0<T||(S.lb(),v.onExit?.(a),K=!0);z(a,new R(a))}var Qa=a=>{L=a;if(D)throw Pa(a),"unwind";Oa(a)};function Ra(){for(var a=v.numThreads;a--;)Ga();ua.unshift(()=>{M++;Sa(()=>ya())})}function Ga(){var a=la("ort-wasm-threaded.worker.js");a=new Worker(a);S.Pa.push(a)}
function Sa(a){D?a():Promise.all(S.Pa.map(S.Ya)).then(a)}
var S={Pa:[],Qa:[],bb:[],Ma:{},Wa(){D?(S.receiveObjectTransfer=S.jb,S.threadInitTLS=S.ab,S.setExitStatus=S.$a):Ra()},$a:a=>L=a,tb:["$terminateWorker"],lb:()=>{for(var a of S.Qa)Fa(a);for(a of S.Pa)Fa(a);S.Pa=[];S.Qa=[];S.Ma=[]},Za:a=>{var b=a.Oa;delete S.Ma[b];S.Pa.push(a);S.Qa.splice(S.Qa.indexOf(a),1);a.Oa=0;Ta(b)},jb(){},ab(){S.bb.forEach(a=>a())},Ya:a=>new Promise(b=>{a.onmessage=h=>{h=h.data;var k=h.cmd;if(h.targetThread&&h.targetThread!=W()){var t=S.Ma[h.targetThread];t?t.postMessage(h,h.transferList):
J(`Internal error! Worker sent a message "${k}" to target pthread ${h.targetThread}, but that thread no longer exists!`)}else if("checkMailbox"===k)X();else if("spawnThread"===k)Ha(h);else if("cleanupThread"===k)S.Za(S.Ma[h.thread]);else if("killThread"===k)h=h.thread,k=S.Ma[h],delete S.Ma[h],Fa(k),Ta(h),S.Qa.splice(S.Qa.indexOf(k),1),k.Oa=0;else if("cancelThread"===k)S.Ma[h.thread].postMessage({cmd:"cancel"});else if("loaded"===k)a.loaded=!0,B&&!a.Oa&&a.unref(),b(a);else if("alert"===k)alert(`Thread ${h.threadId}: ${h.text}`);
else if("setimmediate"===h.target)a.postMessage(h);else if("callHandler"===k)v[h.handler](...h.args);else k&&J(`worker sent an unknown command ${k}`)};a.onerror=h=>{J(`${"worker sent an error!"} ${h.filename}:${h.lineno}: ${h.message}`);throw h;};B&&(a.on("message",h=>a.onmessage({data:h})),a.on("error",h=>a.onerror(h)));var c=[],d=["onExit"],g;for(g of d)v.hasOwnProperty(g)&&c.push(g);a.postMessage({cmd:"load",handlers:c,urlOrBlob:v.mainScriptUrlOrBlob||_scriptDir,wasmMemory:e,wasmModule:sa})})};
v.PThread=S;var Ua=a=>{for(;0<a.length;)a.shift()(v)};v.establishStackSpace=()=>{var a=W(),b=r()[a+52>>>2>>>0];a=r()[a+56>>>2>>>0];Va(b,b-a);U(b)};function Pa(a){if(D)return V(1,0,a);Qa(a)}var Wa=[],Xa;v.invokeEntryPoint=(a,b)=>{var c=Wa[a];c||(a>=Wa.length&&(Wa.length=a+1),Wa[a]=c=Xa.get(a));a=c(b);0<T?S.$a(a):Ya(a)};
function Za(a){this.Va=a-24;this.gb=function(b){r()[this.Va+4>>>2>>>0]=b};this.fb=function(b){r()[this.Va+8>>>2>>>0]=b};this.Wa=function(b,c){this.eb();this.gb(b);this.fb(c)};this.eb=function(){r()[this.Va+16>>>2>>>0]=0}}var $a=0,ab=0;function bb(a,b,c,d){return D?V(2,1,a,b,c,d):cb(a,b,c,d)}
function cb(a,b,c,d){a>>>=0;b>>>=0;c>>>=0;d>>>=0;if("undefined"==typeof SharedArrayBuffer)return J("Current environment does not support SharedArrayBuffer, pthreads are not available!"),6;var g=[];if(D&&0===g.length)return bb(a,b,c,d);a={kb:c,Oa:a,hb:d,qb:g};return D?(a.sb="spawnThread",postMessage(a,g),0):Ha(a)}function db(a,b,c){return D?V(3,1,a,b,c):0}function eb(a,b){if(D)return V(4,1,a,b)}
var fb=a=>{for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);127>=d?b++:2047>=d?b+=2:55296<=d&&57343>=d?(b+=4,++c):b+=3}return b},gb=(a,b,c,d)=>{c>>>=0;if(!(0<d))return 0;var g=c;d=c+d-1;for(var h=0;h<a.length;++h){var k=a.charCodeAt(h);if(55296<=k&&57343>=k){var t=a.charCodeAt(++h);k=65536+((k&1023)<<10)|t&1023}if(127>=k){if(c>=d)break;b[c++>>>0]=k}else{if(2047>=k){if(c+1>=d)break;b[c++>>>0]=192|k>>6}else{if(65535>=k){if(c+2>=d)break;b[c++>>>0]=224|k>>12}else{if(c+3>=d)break;b[c++>>>0]=240|k>>
18;b[c++>>>0]=128|k>>12&63}b[c++>>>0]=128|k>>6&63}b[c++>>>0]=128|k&63}}b[c>>>0]=0;return c-g},hb=(a,b,c)=>gb(a,n(),b,c);function ib(a,b){if(D)return V(5,1,a,b)}function jb(a,b,c){if(D)return V(6,1,a,b,c)}function kb(a,b,c){return D?V(7,1,a,b,c):0}function lb(a,b){if(D)return V(8,1,a,b)}function mb(a,b,c){if(D)return V(9,1,a,b,c)}function nb(a,b,c,d){if(D)return V(10,1,a,b,c,d)}function ob(a,b,c,d){if(D)return V(11,1,a,b,c,d)}function pb(a,b,c,d){if(D)return V(12,1,a,b,c,d)}
function qb(a){if(D)return V(13,1,a)}function rb(a,b){if(D)return V(14,1,a,b)}function sb(a,b,c){if(D)return V(15,1,a,b,c)}function tb(a){a>>>=0;"function"===typeof Atomics.rb&&(Atomics.rb(p(),a>>>2,a).value.then(X),a+=128,Atomics.store(p(),a>>>2,1))}v.__emscripten_thread_mailbox_await=tb;var X=()=>{var a=W();if(a&&(tb(a),a=ub,!K))try{if(a(),!(0<T))try{D?Ya(L):Qa(L)}catch(b){b instanceof R||"unwind"==b||z(1,b)}}catch(b){b instanceof R||"unwind"==b||z(1,b)}};v.checkMailbox=X;
var vb=[],Y=a=>0===a%4&&(0!==a%100||0===a%400),wb=[0,31,60,91,121,152,182,213,244,274,305,335],xb=[0,31,59,90,120,151,181,212,243,273,304,334];function yb(a,b,c,d,g,h,k,t){return D?V(16,1,a,b,c,d,g,h,k,t):-52}function zb(a,b,c,d,g,h,k){if(D)return V(17,1,a,b,c,d,g,h,k)}
var Bb=a=>{var b=fb(a)+1,c=Ab(b);c&&hb(a,c,b);return c},Cb=[],Db={},Fb=()=>{if(!Eb){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ja||"./this.program"},b;for(b in Db)void 0===Db[b]?delete a[b]:a[b]=Db[b];var c=[];for(b in a)c.push(`${b}=${a[b]}`);Eb=c}return Eb},Eb;
function Gb(a,b){if(D)return V(18,1,a,b);a>>>=0;b>>>=0;var c=0;Fb().forEach((d,g)=>{var h=b+c;g=r()[a+4*g>>>2>>>0]=h;for(h=0;h<d.length;++h)aa()[g++>>>0>>>0]=d.charCodeAt(h);aa()[g>>>0>>>0]=0;c+=d.length+1});return 0}function Kb(a,b){if(D)return V(19,1,a,b);a>>>=0;b>>>=0;var c=Fb();r()[a>>>2>>>0]=c.length;var d=0;c.forEach(g=>d+=g.length+1);r()[b>>>2>>>0]=d;return 0}function Lb(a){return D?V(20,1,a):52}function Mb(a,b,c,d){return D?V(21,1,a,b,c,d):52}
function Nb(a,b,c,d,g){return D?V(22,1,a,b,c,d,g):70}var Ob=[null,[],[]];function Pb(a,b,c,d){if(D)return V(23,1,a,b,c,d);b>>>=0;c>>>=0;d>>>=0;for(var g=0,h=0;h<c;h++){var k=r()[b>>>2>>>0],t=r()[b+4>>>2>>>0];b+=8;for(var C=0;C<t;C++){var w=n()[k+C>>>0],y=Ob[a];0===w||10===w?((1===a?qa:J)(Ja(y,0)),y.length=0):y.push(w)}g+=t}r()[d>>>2>>>0]=g;return 0}var Qb=[31,29,31,30,31,30,31,31,30,31,30,31],Rb=[31,28,31,30,31,30,31,31,30,31,30,31];
function Sb(a){var b=Array(fb(a)+1);gb(a,b,0,b.length);return b}var Tb=(a,b)=>{aa().set(a,b>>>0)};
function Ub(a,b,c,d){function g(f,q,u){for(f="number"==typeof f?f.toString():f||"";f.length<q;)f=u[0]+f;return f}function h(f,q){return g(f,q,"0")}function k(f,q){function u(Hb){return 0>Hb?-1:0<Hb?1:0}var F;0===(F=u(f.getFullYear()-q.getFullYear()))&&0===(F=u(f.getMonth()-q.getMonth()))&&(F=u(f.getDate()-q.getDate()));return F}function t(f){switch(f.getDay()){case 0:return new Date(f.getFullYear()-1,11,29);case 1:return f;case 2:return new Date(f.getFullYear(),0,3);case 3:return new Date(f.getFullYear(),
0,2);case 4:return new Date(f.getFullYear(),0,1);case 5:return new Date(f.getFullYear()-1,11,31);case 6:return new Date(f.getFullYear()-1,11,30)}}function C(f){var q=f.Ra;for(f=new Date((new Date(f.Sa+1900,0,1)).getTime());0<q;){var u=f.getMonth(),F=(Y(f.getFullYear())?Qb:Rb)[u];if(q>F-f.getDate())q-=F-f.getDate()+1,f.setDate(1),11>u?f.setMonth(u+1):(f.setMonth(0),f.setFullYear(f.getFullYear()+1));else{f.setDate(f.getDate()+q);break}}u=new Date(f.getFullYear()+1,0,4);q=t(new Date(f.getFullYear(),
0,4));u=t(u);return 0>=k(q,f)?0>=k(u,f)?f.getFullYear()+1:f.getFullYear():f.getFullYear()-1}a>>>=0;b>>>=0;c>>>=0;d>>>=0;var w=r()[d+40>>>2>>>0];d={ob:p()[d>>>2>>>0],nb:p()[d+4>>>2>>>0],Ta:p()[d+8>>>2>>>0],Xa:p()[d+12>>>2>>>0],Ua:p()[d+16>>>2>>>0],Sa:p()[d+20>>>2>>>0],Na:p()[d+24>>>2>>>0],Ra:p()[d+28>>>2>>>0],ub:p()[d+32>>>2>>>0],mb:p()[d+36>>>2>>>0],pb:w?Q(w):""};c=Q(c);w={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y",
"%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var y in w)c=c.replace(new RegExp(y,"g"),w[y]);var Ib="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),Jb="January February March April May June July August September October November December".split(" ");w={"%a":f=>Ib[f.Na].substring(0,3),
"%A":f=>Ib[f.Na],"%b":f=>Jb[f.Ua].substring(0,3),"%B":f=>Jb[f.Ua],"%C":f=>h((f.Sa+1900)/100|0,2),"%d":f=>h(f.Xa,2),"%e":f=>g(f.Xa,2," "),"%g":f=>C(f).toString().substring(2),"%G":f=>C(f),"%H":f=>h(f.Ta,2),"%I":f=>{f=f.Ta;0==f?f=12:12<f&&(f-=12);return h(f,2)},"%j":f=>{for(var q=0,u=0;u<=f.Ua-1;q+=(Y(f.Sa+1900)?Qb:Rb)[u++]);return h(f.Xa+q,3)},"%m":f=>h(f.Ua+1,2),"%M":f=>h(f.nb,2),"%n":()=>"\n","%p":f=>0<=f.Ta&&12>f.Ta?"AM":"PM","%S":f=>h(f.ob,2),"%t":()=>"\t","%u":f=>f.Na||7,"%U":f=>h(Math.floor((f.Ra+
7-f.Na)/7),2),"%V":f=>{var q=Math.floor((f.Ra+7-(f.Na+6)%7)/7);2>=(f.Na+371-f.Ra-2)%7&&q++;if(q)53==q&&(u=(f.Na+371-f.Ra)%7,4==u||3==u&&Y(f.Sa)||(q=1));else{q=52;var u=(f.Na+7-f.Ra-1)%7;(4==u||5==u&&Y(f.Sa%400-1))&&q++}return h(q,2)},"%w":f=>f.Na,"%W":f=>h(Math.floor((f.Ra+7-(f.Na+6)%7)/7),2),"%y":f=>(f.Sa+1900).toString().substring(2),"%Y":f=>f.Sa+1900,"%z":f=>{f=f.mb;var q=0<=f;f=Math.abs(f)/60;return(q?"+":"-")+String("0000"+(f/60*100+f%60)).slice(-4)},"%Z":f=>f.pb,"%%":()=>"%"};c=c.replace(/%%/g,
"\x00\x00");for(y in w)c.includes(y)&&(c=c.replace(new RegExp(y,"g"),w[y](d)));c=c.replace(/\0\0/g,"%");y=Sb(c);if(y.length>b)return 0;Tb(y,a);return y.length-1}S.Wa();
var Vb=[Oa,Pa,bb,db,eb,ib,jb,kb,lb,mb,nb,ob,pb,qb,rb,sb,yb,zb,Gb,Kb,Lb,Mb,Nb,Pb],Zb={b:function(a,b,c){a>>>=0;(new Za(a)).Wa(b>>>0,c>>>0);$a=a;ab++;throw $a;},L:function(a){Wb(a>>>0,!A,1,!ka,131072,!1);S.ab()},j:function(a){a>>>=0;D?postMessage({cmd:"cleanupThread",thread:a}):S.Za(S.Ma[a])},H:cb,h:db,S:eb,D:ib,F:jb,T:kb,Q:lb,J:mb,P:nb,n:ob,E:pb,B:qb,R:rb,C:sb,p:()=>1,z:function(a,b){a>>>=0;a==b>>>0?setTimeout(()=>X()):D?postMessage({targetThread:a,cmd:"checkMailbox"}):(a=S.Ma[a])&&a.postMessage({cmd:"checkMailbox"})},
I:function(a,b,c,d){b>>>=0;vb.length=c;d=d>>>0>>>3;for(var g=0;g<c;g++)vb[g]=ea()[d+g>>>0];a=0>a?Ea[-a-1]:Vb[a];S.ib=b;b=a.apply(null,vb);S.ib=0;return b},K:tb,o:function(a){B&&S.Ma[a>>>0].ref()},s:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);p()[c>>>2>>>0]=a.getUTCSeconds();p()[c+4>>>2>>>0]=a.getUTCMinutes();p()[c+8>>>2>>>0]=a.getUTCHours();p()[c+12>>>2>>>0]=a.getUTCDate();p()[c+16>>>2>>>0]=a.getUTCMonth();p()[c+20>>>2>>>0]=a.getUTCFullYear()-1900;
p()[c+24>>>2>>>0]=a.getUTCDay();a=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0;p()[c+28>>>2>>>0]=a},t:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);p()[c>>>2>>>0]=a.getSeconds();p()[c+4>>>2>>>0]=a.getMinutes();p()[c+8>>>2>>>0]=a.getHours();p()[c+12>>>2>>>0]=a.getDate();p()[c+16>>>2>>>0]=a.getMonth();p()[c+20>>>2>>>0]=a.getFullYear()-1900;p()[c+24>>>2>>>0]=a.getDay();b=(Y(a.getFullYear())?wb:xb)[a.getMonth()]+a.getDate()-1|0;p()[c+28>>>
2>>>0]=b;p()[c+36>>>2>>>0]=-(60*a.getTimezoneOffset());b=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();var d=(new Date(a.getFullYear(),0,1)).getTimezoneOffset();a=(b!=d&&a.getTimezoneOffset()==Math.min(d,b))|0;p()[c+32>>>2>>>0]=a},u:function(a){a>>>=0;var b=new Date(p()[a+20>>>2>>>0]+1900,p()[a+16>>>2>>>0],p()[a+12>>>2>>>0],p()[a+8>>>2>>>0],p()[a+4>>>2>>>0],p()[a>>>2>>>0],0),c=p()[a+32>>>2>>>0],d=b.getTimezoneOffset(),g=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),h=(new Date(b.getFullYear(),
0,1)).getTimezoneOffset(),k=Math.min(h,g);0>c?p()[a+32>>>2>>>0]=Number(g!=h&&k==d):0<c!=(k==d)&&(g=Math.max(h,g),b.setTime(b.getTime()+6E4*((0<c?k:g)-d)));p()[a+24>>>2>>>0]=b.getDay();c=(Y(b.getFullYear())?wb:xb)[b.getMonth()]+b.getDate()-1|0;p()[a+28>>>2>>>0]=c;p()[a>>>2>>>0]=b.getSeconds();p()[a+4>>>2>>>0]=b.getMinutes();p()[a+8>>>2>>>0]=b.getHours();p()[a+12>>>2>>>0]=b.getDate();p()[a+16>>>2>>>0]=b.getMonth();p()[a+20>>>2>>>0]=b.getYear();a=b.getTime();isNaN(a)?(p()[Xb()>>>2>>>0]=61,a=-1):a/=1E3;
return Yb((P=a,1<=+Math.abs(P)?0<P?+Math.floor(P/4294967296)>>>0:~~+Math.ceil((P-+(~~P>>>0))/4294967296)>>>0:0)),a>>>0},q:yb,r:zb,y:function(a,b,c){function d(w){return(w=w.toTimeString().match(/\(([A-Za-z ]+)\)$/))?w[1]:"GMT"}a>>>=0;b>>>=0;c>>>=0;var g=(new Date).getFullYear(),h=new Date(g,0,1),k=new Date(g,6,1);g=h.getTimezoneOffset();var t=k.getTimezoneOffset(),C=Math.max(g,t);r()[a>>>2>>>0]=60*C;p()[b>>>2>>>0]=Number(g!=t);a=d(h);b=d(k);a=Bb(a);b=Bb(b);t<g?(r()[c>>>2>>>0]=a,r()[c+4>>>2>>>0]=b):
(r()[c>>>2>>>0]=b,r()[c+4>>>2>>>0]=a)},c:()=>{ra("")},O:function(a,b,c){a>>>=0;b>>>=0;c>>>=0;Cb.length=0;for(var d;d=n()[b++>>>0];){var g=105!=d;g&=112!=d;c+=g&&c%8?4:0;Cb.push(112==d?r()[c>>>2>>>0]:105==d?p()[c>>>2>>>0]:ea()[c>>>3>>>0]);c+=g?8:4}return Ea[a].apply(null,Cb)},k:()=>{},i:()=>Date.now(),U:()=>{T+=1;throw"unwind";},A:function(){return 4294901760},e:()=>performance.timeOrigin+performance.now(),f:()=>B?require("os").cpus().length:navigator.hardwareConcurrency,x:function(a){a>>>=0;var b=
n().length;if(a<=b||4294901760<a)return!1;for(var c=1;4>=c;c*=2){var d=b*(1+.2/c);d=Math.min(d,a+100663296);var g=Math;d=Math.max(a,d);a:{g=(g.min.call(g,4294901760,d+(65536-d%65536)%65536)-e.buffer.byteLength+65535)/65536;try{e.grow(g);m();var h=1;break a}catch(k){}h=void 0}if(h)return!0}return!1},M:Gb,N:Kb,G:Qa,g:Lb,m:Mb,v:Nb,l:Pb,a:e||v.wasmMemory,w:Ub,d:function(a,b,c,d){return Ub(a>>>0,b>>>0,c>>>0,d>>>0)}},Z=function(){function a(c,d){Z=c.exports;Z=$b();S.bb.push(Z.za);Xa=Z.Aa;va.unshift(Z.V);
sa=d;ya();return Z}var b={a:Zb};M++;if(v.instantiateWasm)try{return v.instantiateWasm(b,a)}catch(c){J(`Module.instantiateWasm callback failed with error: ${c}`),x(c)}Da(b,function(c){a(c.instance,c.module)}).catch(x);return{}}();v._OrtInit=(a,b)=>(v._OrtInit=Z.W)(a,b);v._OrtGetLastError=(a,b)=>(v._OrtGetLastError=Z.X)(a,b);v._OrtCreateSessionOptions=(a,b,c,d,g,h,k,t,C,w)=>(v._OrtCreateSessionOptions=Z.Y)(a,b,c,d,g,h,k,t,C,w);
v._OrtAppendExecutionProvider=(a,b)=>(v._OrtAppendExecutionProvider=Z.Z)(a,b);v._OrtAddFreeDimensionOverride=(a,b,c)=>(v._OrtAddFreeDimensionOverride=Z._)(a,b,c);v._OrtAddSessionConfigEntry=(a,b,c)=>(v._OrtAddSessionConfigEntry=Z.$)(a,b,c);v._OrtReleaseSessionOptions=a=>(v._OrtReleaseSessionOptions=Z.aa)(a);v._OrtCreateSession=(a,b,c)=>(v._OrtCreateSession=Z.ba)(a,b,c);v._OrtReleaseSession=a=>(v._OrtReleaseSession=Z.ca)(a);v._OrtGetInputOutputCount=(a,b,c)=>(v._OrtGetInputOutputCount=Z.da)(a,b,c);
v._OrtGetInputName=(a,b)=>(v._OrtGetInputName=Z.ea)(a,b);v._OrtGetOutputName=(a,b)=>(v._OrtGetOutputName=Z.fa)(a,b);v._OrtFree=a=>(v._OrtFree=Z.ga)(a);v._OrtCreateTensor=(a,b,c,d,g,h)=>(v._OrtCreateTensor=Z.ha)(a,b,c,d,g,h);v._OrtGetTensorData=(a,b,c,d,g)=>(v._OrtGetTensorData=Z.ia)(a,b,c,d,g);v._OrtReleaseTensor=a=>(v._OrtReleaseTensor=Z.ja)(a);v._OrtCreateRunOptions=(a,b,c,d)=>(v._OrtCreateRunOptions=Z.ka)(a,b,c,d);v._OrtAddRunConfigEntry=(a,b,c)=>(v._OrtAddRunConfigEntry=Z.la)(a,b,c);
v._OrtReleaseRunOptions=a=>(v._OrtReleaseRunOptions=Z.ma)(a);v._OrtCreateBinding=a=>(v._OrtCreateBinding=Z.na)(a);v._OrtBindInput=(a,b,c)=>(v._OrtBindInput=Z.oa)(a,b,c);v._OrtBindOutput=(a,b,c,d)=>(v._OrtBindOutput=Z.pa)(a,b,c,d);v._OrtClearBoundOutputs=a=>(v._OrtClearBoundOutputs=Z.qa)(a);v._OrtReleaseBinding=a=>(v._OrtReleaseBinding=Z.ra)(a);v._OrtRunWithBinding=(a,b,c,d,g)=>(v._OrtRunWithBinding=Z.sa)(a,b,c,d,g);v._OrtRun=(a,b,c,d,g,h,k,t)=>(v._OrtRun=Z.ta)(a,b,c,d,g,h,k,t);
v._OrtEndProfiling=a=>(v._OrtEndProfiling=Z.ua)(a);var Xb=()=>(Xb=Z.va)(),W=v._pthread_self=()=>(W=v._pthread_self=Z.wa)(),Ab=v._malloc=a=>(Ab=v._malloc=Z.xa)(a);v._free=a=>(v._free=Z.ya)(a);v.__emscripten_tls_init=()=>(v.__emscripten_tls_init=Z.za)();var Wb=v.__emscripten_thread_init=(a,b,c,d,g,h)=>(Wb=v.__emscripten_thread_init=Z.Ba)(a,b,c,d,g,h);v.__emscripten_thread_crashed=()=>(v.__emscripten_thread_crashed=Z.Ca)();
var Na=(a,b,c,d)=>(Na=Z.Da)(a,b,c,d),Ta=a=>(Ta=Z.Ea)(a),Ya=v.__emscripten_thread_exit=a=>(Ya=v.__emscripten_thread_exit=Z.Fa)(a),ub=()=>(ub=Z.Ga)(),Yb=a=>(Yb=Z.Ha)(a),Va=(a,b)=>(Va=Z.Ia)(a,b),Ka=()=>(Ka=Z.Ja)(),U=a=>(U=Z.Ka)(a),Ma=a=>(Ma=Z.La)(a);function $b(){var a=Z;a=Object.assign({},a);var b=d=>()=>d()>>>0,c=d=>g=>d(g)>>>0;a.va=b(a.va);a.wa=b(a.wa);a.xa=c(a.xa);a.emscripten_main_runtime_thread_id=b(a.emscripten_main_runtime_thread_id);a.Ja=b(a.Ja);a.La=c(a.La);return a}v.wasmMemory=e;
v.stackAlloc=Ma;v.stackSave=Ka;v.stackRestore=U;v.keepRuntimeAlive=()=>0<T;v.UTF8ToString=Q;v.stringToUTF8=hb;v.lengthBytesUTF8=fb;v.ExitStatus=R;v.PThread=S;var ac;N=function bc(){ac||cc();ac||(N=bc)};function cc(){if(!(0<M))if(D)ha(v),D||Ua(va),startWorker(v);else{if(v.preRun)for("function"==typeof v.preRun&&(v.preRun=[v.preRun]);v.preRun.length;)ua.unshift(v.preRun.shift());Ua(ua);0<M||ac||(ac=!0,v.calledRun=!0,K||(D||Ua(va),ha(v),D||Ua(wa)))}}cc();


  return moduleArg.ready
}
);
})();
;
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasmThreaded;
else if (typeof define === 'function' && define['amd'])
  define([], () => ortWasmThreaded);
